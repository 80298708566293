import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';
import { Pagination } from 'models/store';
import { getPaginationPararms } from './utils.service';

export const searchClinic = async (term: string) => {
  const { CLINIC: endpoint } = ENDPOINTS.REFERENCE.SEARCH;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}`);
};

export const searchPatients = async (
  term: string,
  field: string,
  clinicId: number | null,
  program: string | null,
  trialStatus: string | null,
  endpointFilter: 'navigator' | 'watchlist' | null,
  accountId: number,
  pagination?: Pagination
) => {
  if (endpointFilter === 'watchlist') {
    const { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.WATCHLIST;
    const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
    return http.get(endpoint.name, `${endpoint.url}?${field}=${term}${paginationParams}`);
  }
  if (endpointFilter === 'navigator') {
    const { FROM_NAVIGATOR: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
    const searchKey = field === 'id' ? 'member_id' : 'term';
    const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
    return http.get(endpoint.name, `${endpoint.url}?${searchKey}=${term}&account_id=${accountId}${paginationParams}`);
  }
  const { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
  const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
  return http.get(
    endpoint.name,
    `${endpoint.url}?${field}=${term}${clinicId ? `&clinic_id=${clinicId}` : ''}${
      program ? `&eligible_program=${program}` : ''
    }${trialStatus ? `&trial_status=${trialStatus}&has_trial=true` : ''}${paginationParams}`
  );
};

export const searchPatientsOnWachlist = async (term: string, field: 'term' | 'id', pagination?: Pagination) => {
  const { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.WATCHLIST;
  const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
  return http.get(endpoint.name, `${endpoint.url}?${field}=${term}${paginationParams}`);
};

export async function getPatientListCount(
  term: string,
  field: string,
  clinicId: number | null,
  eligibleProgram: string | null,
  watchlist: boolean
) {
  const { GET_COUNT: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
  return http.get(
    endpoint.name,
    `${endpoint.url}?${field}=${term}${clinicId ? `&clinic_id=${clinicId}` : ''}${
      eligibleProgram ? `&eligible_program=${eligibleProgram}` : ''
    }${watchlist ? `&watchlist=${watchlist}` : ''}`
  );
}

export const searchPatientOnReminder = () => {
  const { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
  return http.get(endpoint.name, `${endpoint.url}?for_status_update=true`);
};

export const searchNavigatorPatients = async (
  term: string,
  field: string,
  accountId: number,
  pagination?: Pagination
) => {
  const { FROM_NAVIGATOR: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
  const searchKey = field === 'name' ? 'term' : 'member_id';
  const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
  return http.get(endpoint.name, `${endpoint.url}?${searchKey}=${term}&account_id=${accountId}${paginationParams}`);
};

export const searchAllPatients = async (term: string) => {
  const search = new URLSearchParams();
  search.set('page', '1');
  search.set('per_page', '50');
  if (term) search.set('term', encodeURI(term));
  return http.get('searchPatient', `/api/doctor/v4/patient?${search.toString()}`);
};

export const searchHmo = async (term: string) => {
  let { HMO: endpoint } = ENDPOINTS.REFERENCE.SEARCH;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}`);
};

export const searchDoctorByClinic = async (clinicId: number) => {
  let endpoint = ENDPOINTS.V3.SEARCH_DOCTOR;
  return http.get(endpoint.name, `${endpoint.url}?clinic_id=${clinicId}`);
};

export const searchDoctorByClinicFlag = async (clinicId: number, isProser: boolean) => {
  let endpoint = ENDPOINTS.V3.SEARCH_DOCTOR;
  return http.get(endpoint.name, `${endpoint.url}?clinic_id=${clinicId}&is_proser=${isProser}&per_page=100`);
};

export const searchDoctorByClinicTerm = async (clinicId: number, isProser: boolean, term: string) => {
  let endpoint = ENDPOINTS.V3.SEARCH_DOCTOR;
  const termParam = term ? `&term=${term}` : '';
  return http.get(
    endpoint.name,
    `${endpoint.url}?clinic_id=${clinicId}&is_proser=${isProser}&per_page=100${termParam}`
  );
};

export const getBorrowersByPatientId = async (patientId: number) => {
  let { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.BORROWERS;
  return http.get(endpoint.name, `${endpoint.url}?patient_id=${patientId}`);
};

export const searchGeneric = async (term: string) => {
  let { PRESCRIPTION_GENERIC: endpoint } = ENDPOINTS.REFERENCE.SEARCH;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}`);
};

export const searchBrand = async (term: string) => {
  let { PRESCRIPTION_BRAND: endpoint } = ENDPOINTS.REFERENCE.SEARCH;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}`);
};
